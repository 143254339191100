import React from "react";

import Breadcrum from "../Common/Breadcrum";
import ContactForm from "../Common/ContactForm";
import ContactAddress from "../Common/ContactAddress";
import HelmetMetaData from '../Common/Default';
class Contact extends React.Component {
  componentDidMount(){
    window.scrolltoTop();
  }
  render() {
    return (
      <>
        <HelmetMetaData title="Contact Us | Chauhan Vijay"
          description="Contact Us for the Web design & Development work. We have provide reliable solutions for your business | Chauhan Vijay"
          keywords="Contact Us"
        ></HelmetMetaData>
        <Breadcrum pagetitle="Contact Us" breadcrumclassName="inner-banner" />
        <section className="c-padd-80">
          <div className="container">
            <div className="row ">
              <ContactAddress />
              <ContactForm />
            </div>
          </div>
        </section>
      </>
    );
  }

};
export default Contact;
