import React from "react";
import about from '../api/about.json';
import { NavLink } from "react-router-dom";
class AboutUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      items: about,
    };
  }
  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loader">Loading...</div>;
    } else {
      return (
        <>
          <div className="row">
            <div className="col-lg-6">
              <div className="home-about-img">
                <img alt={items.title} src="images/about-img.jpg" className="img-fluid" />
                <div className="experience-box"> <h5><span>{(new Date().getFullYear()) - 2015}+</span> Years Of Experience</h5></div>

              </div>
            </div>

            <div className="col-lg-6 ">
              <div className="home-about-text">
                <h3 className=" h2 t-color-blue mb-30">{items.title}</h3>
                <p className="mb-10">{items.content}</p>
                <NavLink
                  exact
                  to="/about"
                  activeClassName="active"
                  className="btn btn-blue-border"
                >
                  Read More
                    </NavLink>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}
export default AboutUs;
