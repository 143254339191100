import React from "react";
import { NavLink } from "react-router-dom";

const Breadcrum = (props) => {
  let projectLink = '';
  if (props.isProject === '1') {
    projectLink =
      <>
        <li>
          <NavLink exact to="/portfolio">
            Portfolio
        </NavLink>
        </li>
      </>
  };

  return (
    <>
      <section className="page-header">
        <div className="container-fluid">
          <h1 className="">{props.pagetitle}</h1>
          <ul id="breadcrumbs" className="breadcrumbs none-style">
            <li><NavLink exact to="/">
              Home
                </NavLink></li>
            {projectLink}
            <li className="active">{props.pagetitle}</li>
          </ul>
        </div>
      </section>
    </>
  );
};
export default Breadcrum;
