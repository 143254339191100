import React from "react";
import AboutValueBox from "./AboutValueBox";
import data from '../api/ourcorevalue.json';
class OurValue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      items: data,
    };
  }

  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loader">Loading...</div>;
    } else {
      const ourValue = items.map((avatar, i) => {
        return (<div key={avatar.id}>
          <div className="mb-20">
            <AboutValueBox title={avatar.title} content={avatar.content} />
          </div>
          <hr />
        </div>
        );
      });
      return (
        <>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 order-lg-2 order-md-2">
                <div className="journey-img-box">
                  <img src="images/journey-img.jpg" className="img-fluid" alt="About Us" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 order-lg-1 order-md-1">
                <h3 className="h2 t-color-blue mb-30">Our Journey’s Path</h3>
                {ourValue}
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}
export default OurValue;
