import React from 'react';
import { NavLink } from "react-router-dom";
const MenuLink = () => {
    return (
        <>
            <li className="nav-item">
                <NavLink
                    exact
                    to="/"
                    activeClassName="active"
                    className=""
                >
                    Home
                    </NavLink>
            </li>
            <li className="nav-item">
                <NavLink
                    exact
                    to="/about"
                    activeClassName="active"
                    className=""
                >
                    About Us
                    </NavLink>
            </li>
            <li className="nav-item"><NavLink
                exact
                to="/how-i-work"
                activeClassName="active"
                className=""
            >
                How I Work
                    </NavLink></li>
            <li className="nav-item">
                <NavLink
                    exact
                    to="/service"
                    activeClassName="active"
                    className=""
                >
                    Services
                    </NavLink>
            </li>
            <li className="nav-item">
                <NavLink
                    exact
                    to="/portfolio"
                    activeClassName="active"
                    className=""
                >
                    Portfolio
                    </NavLink>
            </li>
            <li className="nav-item">
                <NavLink
                    exact
                    to="/contact"
                    activeClassName="active"
                    className=""
                >
                    Contact
                    </NavLink>
            </li>
        </>
    )
}
export default MenuLink;