import React from "react";
import { NavLink } from "react-router-dom";
class HeaderSlider extends React.Component {
  render() {
    return (
      <>
        <section className="banner-section">
          <div className="banner-img"></div>
          <div className="container-fluid  h-100">
            <div className="row h-100 align-items-center">
              <div className="col-lg-6 col-md-6 banner-text">
                <h4 className="f-400 text-uppercase">Welcome to</h4>
                <h1 className="Righteous text-uppercase">Chauhan Vijay</h1>
                <h3 className="Righteous">Web Design & Development</h3>
                <NavLink
                  exact
                  to="/contact"
                  activeClassName="active"
                  className="btn btn-white-border"
                >
                  <i className="fas fa-envelope"></i> Inquiry Now
                    </NavLink>
              </div>
            </div>
          </div>

        </section>
      </>
    );
  }
}
export default HeaderSlider;
