import React from "react";
import TestimonialBlock from "../Common/TestimonialBlock";
import testimonial from '../api/testimonial.json';

class Testimonial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      items: testimonial,
    };
  }
  componentDidMount(){
    window.testimonialSlider();
  }
  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loader">Loading...</div>;
    } else {
      const TestimonialData = items.map((value, i) => {
        return (
          <div key={i} className="item">
            <TestimonialBlock
              name={value.name}
              from={value.from}
              position={value.position}
              image={window.location.origin + "/img/testimonial/" + value.image}
              content={value.content}
              id={i}
            />
          </div>
        );
      });

      return (
        <>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-6 col-md-6 offset-md-6">
                <h3 className="h2 t-color-blue text-capitalize mb-30">testimonial</h3>
                <div className="testimonial-carousel owl-carousel owl-theme">
                  {TestimonialData}
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}
export default Testimonial;
