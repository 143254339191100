import React from "react";

const TestimonialBlock = (props) => {
  return (
    <>
      
      <div className="row">
        <div className="col-md-12">
      <div className="embed-responsive embed-responsive-16by9 pl-5">
        <iframe key={props.id} className="embed-responsive-item" data-src={props.content} width="100%" height="100%"></iframe>
        </div>
      </div>
      </div>
      <div className="testimonial-header float-right">
        <div className="testimonial-text">
          <h5 className="font-weight-bold">{props.name}</h5>
          <p className="font-weight-bold font-italic">{props.position}</p>
          <p>{props.from}</p>

        </div>
      </div>
    </>
  );
};
export default TestimonialBlock;
