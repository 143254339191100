import React from "react";
import Breadcrum from "../Common/Breadcrum";
import portfolio from '../api/portfolio.json';
import HelmetMetaData from "../Common/Default";
var parse = require('html-react-parser');
class ProjectDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: true,
            items: portfolio,
        };
        const pageURL = window.location.href;
        const slug = pageURL.substr(pageURL.lastIndexOf('/') + 1);
        portfolio.filter(portfolio => portfolio.slug === slug).map(filteredProject => (
            this.state = {
                error: null,
                isLoaded: true,
                items: filteredProject,
            }
        ))
        this.state.items.baseurl = window.location.origin;
    }
    componentDidMount(){
        window.scrolltoTop();
      }
    render() {
        const { error, isLoaded, items } = this.state;
        const services = items.services.map((value, i) => {
            return (
                <li key={i}>{value}</li>
            );
        });
        const technology = items.technology.map((value, i) => {
            return (
                <li key={i}>{value}</li>
            );
        });
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div className="loader">Loading...</div>;
        } else {
            return (
                <>
                    <HelmetMetaData title={items.title}
                        description={items.description}
                        image={
                            this.state.items.baseurl +
                            "/img/projects/" + items.image
                        }
                    ></HelmetMetaData>
                    <Breadcrum isProject="1" pagetitle={items.title} breadcrumclassName="inner-banner" />
                    <section className="c-padd-80 portfolio-inner-page">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-8 order-lg-2">
                                    <img src={window.location.origin + "/img/projects/" + items.image} alt={items.title} className="img-fluid" />
                                </div>

                                <div className="col-lg-4 order-lg-1">
                                    <h3>{items.title}</h3>
                                    <span>{parse(items.description)}</span>
                                    <a rel="noopener noreferrer" href={items.url} target="_blank">Visit Site</a>
                                    <div className="portfolio-bottom">
                                        <ul>
                                            <h4>Services</h4>
                                            {services}
                                        </ul>

                                        <ul>
                                            <h4>Technology</h4>
                                            {technology}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            );
        }
    }

};
export default ProjectDetail;
