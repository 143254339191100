import React from 'react';
import technology from '../api/technology.json';
class Technology extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: true,
            items: technology,
        };
    }
    render() {
        const { error, isLoaded, items } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div className="loader">Loading...</div>;
        } else {
            const ServiceData = items.map((value, i) => {
                return (
                    <div key={i} className="col-lg-4 col-md-4 col-sm-4 col-6">
                        <div className="technogies-box">
                            <img src={window.location.origin + "/images/" + value.image} className="img-fluid" alt={value.title} />
                        </div>
                    </div>
                );
            });
            return (
                <>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <h3 className="h2 t-color-blue mb-30">Technologies I Use</h3>
                                <p>We experience using the latest website building technologies to give out the most immeasurable performing site.</p>
                            </div>

                            <div className="col-lg-7">
                                <div className="row">
                                    {ServiceData}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }
}
export default Technology;