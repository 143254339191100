import React from "react";
import Breadcrum from "../Common/Breadcrum";
import PortfolioInfo from "../Common/PortfolioInfo";
import HelmetMetaData from '../Common/Default';
class Portfolio extends React.Component {
  componentDidMount(){
    window.scrolltoTop();
  }
  render() {
    return (
      <>
        <HelmetMetaData title="Our Portfolio | Vijay Chauhan"
          description="Our Portfolio | Chauhan Vijay"
          keywords="Our Portfolio, Client Projects"
        ></HelmetMetaData>
        <Breadcrum pagetitle="Our Portfolio" breadcrumclassName="inner-banner" />
        <section className="c-padd-80 portfolio-page">
          <PortfolioInfo />
        </section>
      </>
    );
  }

};
export default Portfolio;
