import React from "react";
import emailjs from 'emailjs-com';

class ContactForm extends React.Component {
  sendEmail(e) {
    e.preventDefault();
    emailjs.sendForm('service_2ag1u8x', 'template_b53epil', e.target, 'user_yRyuBHWKw8MqNo6x7cb1P')
      .then((result) => {
        alert("Your inquery is successfully sent to the admin. We will contact you soon.");
      }, (error) => {
        alert("Oops, something want wrong. Please try again later");
      });
    e.target.reset();
  }
  render() {
    return (
      <>
        <div className="col-lg-7 col-md-12">
          <h3 className="t-color-blue text-uppercase mb-30">Get In Touch</h3>
          <form
            formAction="#"
            id="contactForm"
            method="POST"
            className="contact-form"
            onSubmit={this.sendEmail}
          >
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="form-group ">
                  <input data-validator="required"
                    type="text"
                    name="name"
                    id="name"
                    required="required"
                    className="form-control"
                    data-error="Please enter your name"
                    placeholder="Your Name*" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="form-group ">
                  <input type="number"
                    required="required"
                    name="phone_number"
                    id="phone_number"
                    data-error="Please enter your number"
                    className="form-control"
                    placeholder="Your Phone" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="form-group">
                  <input type="email"
                    name="email"
                    id="email"
                    required="required"
                    className="form-control"
                    data-error="Please enter your email"
                    placeholder="E-mail*" />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="form-group">
                  <input
                    type="text"
                    name="subject"
                    required="required"
                    id="msg_subject"
                    className="form-control"
                    data-error="Please enter your subject"
                    placeholder="Your Subject"
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <textarea
                    name="message"
                    className="form-control"
                    required="required"
                    id="message"
                    cols="30"
                    rows="8"
                    data-error="Write your message"
                    placeholder="Your Message"
                  ></textarea>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group mb-0">
                  <button type="submit" className="btn btn-blue-border">Submit Now</button>
                </div>
              </div>
            </div>
          </form>

        </div>
      </>
    );
  }
}
export default ContactForm;
