import React from "react";
import HeaderSlider from "../Common/HeaderSlider";
import AboutUs from "../Common/AboutUs";
import ProjectInfo from "../Common/ProjectInfo";
import Testimonial from "../Common/Testimonial";
import WorkingProcess from "../Common/WorkingProcess";
import ServiceInfo from "../Common/ServiceInfo";
import Technology from "../Common/Technology";
import HelmetMetaData from '../Common/Default';

class Home extends React.Component {
  componentDidMount(){
    window.scrolltoTop();
  }
  render() {
    return (
      <>
        <HelmetMetaData title="Web Design & Development Company Vadodara"
          description="I'm Vijay Chauhan a Professional Full-stack Website Designer & Web Developer with 5+ years of experience. In the Last 5 years, I've helped 50+ businesses to improve their product experience With Latest and Highly secured technologies like Laravel, Codeigniter, Andriod App & Moodle I have built 100+ websites for a broad range of industries from small and mid-level businesses to high-end companies & still counting... My Cleared Aim has always been to build a website that looks Professional, Secured, Reliable, and AFFORDABLE. Let's Plan your Project & Build Robust Solutions."
          keywords="Full-stack Website Developer, Web Design, E-Commerce Development"
        ></HelmetMetaData>
        <HeaderSlider />
        <section className="home-about-section c-padd-80">
          <div className="container">
            <AboutUs />
          </div>
        </section>
        <section className="how-work-section c-padd-80">
          <WorkingProcess />
        </section>
        <section className="c-padd-80 help-with-section">
          <ServiceInfo />
        </section>
        <section className="c-padd-80 count-section">
          <ProjectInfo />
        </section>
        <section className="c-padd-80 technogies-section">
          <Technology />
        </section>
        <section className="c-padd-80 testimonial-section">
          <div className="testimonial-bg">
          </div>
          <Testimonial />
        </section>
      </>
    );
  }
}
export default Home;
