import React from "react";
import MenuLink from '../Common/MenuLink';
import { NavLink } from "react-router-dom";
const Navbar = () => {
  return (
    <>
      <section className="top-header">
        <div className="container-fluid">
          <ul>
            <li><span><i className="far fa-envelope"></i></span> <a href="mailto:chauhanvijay.mca@gmail.com">chauhanvijay.mca@gmail.com</a></li>
            <li><span><i className="far fa-clock"></i></span> Monday - Friday: 09:00 - 20:00</li>
          </ul>
        </div>
      </section>
      <header>
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg navbar-light row" >
            <NavLink
              exact
              to="/"
              activeClassName="active"
              className="navbar-brand"
            >
              <img src={window.location.origin + "/images/logo.png"} alt="Chauhan Vijay Logo"  className="img-fluid" /></NavLink>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav m-auto">
                <MenuLink />
              </ul>
            </div>
            <div className="header-contact">
              <div className="contact-number">
                <div className="icon"><img alt="Chauhan Vijay Phone Call" src={window.location.origin + "/images/phone-call.png"} className="img-fluid" /></div>
                <a href="tel:(+91) 9574689275">(+91) 9574689275</a>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};
export default Navbar;
