import React from "react";
import Breadcrum from "../Common/Breadcrum";
import services from '../api/services.json';
import HelmetMetaData from '../Common/Default';
class Service extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      items: services,
    };
  }
  componentDidMount(){
    window.scrolltoTop();
  }
  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loader">Loading...</div>;
    } else {
      const ServiceData = items.map((value, i) => {
        if (i % 2 !== 0) {
          return (
              <div key={i} className="row align-items-center mb-30">
                <div className="col-lg-6 col-md-6 order-lg-2 order-md-2">
                  <div className="services-img">
                    <img src={window.location.origin + "/images/" + value.image} alt={value.title} className="img-fluid" /></div>
                </div>
                <div className="col-lg-6 col-md-6 order-lg-1 order-md-1">
                  <h3 className="text-uppercase t-color-blue mb-10">{value.title}</h3>
                  <p>{value.description}</p>
                </div>
              </div>
          );
        } else {
          return (
              <div key={i} className="row align-items-center mb-30">
                <div className="col-lg-6 col-md-6">
                  <div className="services-img">
                    <img alt="Chauhan Vijay Services" src={window.location.origin + "/images/" + value.image} className="img-fluid" /></div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <h3 className="text-uppercase t-color-blue mb-10">{value.title}</h3>
                  <p>{value.description}</p>
                </div>
              </div>
          );
        }
      });
      return (
        <>
          <HelmetMetaData title="Services | Vijay Chauhan"
            description="Services | Chauhan Vijay"
            keywords="Services, Web Design, Web Development, E-Commerce Development"
          ></HelmetMetaData>
          <Breadcrum pagetitle="Services" breadcrumclassName="inner-banner" />
          <section className="c-padd-80 services-page">
            <div className="container">
              {ServiceData}
            </div>
          </section>
        </>
      );
    }
  }

};
export default Service;
