import React from "react";
import { NavLink } from "react-router-dom";
const PortfolioBlock = (props) => {
  return (
    <>
      <NavLink className="portfolio-img" exact to={`/portfolio/${props.slug}`}>
        <img src={props.image} style={{height: "364px"}} alt={props.title} className="img-fluid" />
        <h4>{props.title}</h4>
      </NavLink>
    </>
  );
};
export default PortfolioBlock;
