import React from "react";
import about from '../api/about.json';
import ProjectInfo from "../Common/ProjectInfo";
import Breadcrum from "../Common/Breadcrum";
import OurValue from "../Common/OurValue";
import HelmetMetaData from '../Common/Default';
class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      items: about,
    };
  }
  componentDidMount(){
    window.scrolltoTop();
  }
  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loader">Loading...</div>;
    } else {
      return (
        <>
          <HelmetMetaData title="About Us | Chauhan Vijay"
            description="About Us | Chauhan Vijay"
            keywords="Full-stack Website Developer, About Me"
          ></HelmetMetaData>
          <Breadcrum pagetitle="About Us" breadcrumclassName="inner-banner" />
          <section className="c-padd-80 about-us-page">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-6">
                  <div className="about-img-box">
                    <img src="images/about-page-img.jpg" alt={items.title} className="img-fluid" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <h3 className="h2 t-color-blue mb-20">{items.title}</h3>
                  <p className="line-height-30">{items.content}</p>
                </div>
              </div>
            </div>
          </section>
          <section className="c-padd-80 count-section">
            <ProjectInfo />
          </section>
          <section className="c-padd-80 journey-section">
            <OurValue />
          </section>
        </>
      );
    }
  }
}
export default About;
