import React from "react";
import workingprocess from '../api/workingprocess.json';
import Breadcrum from "../Common/Breadcrum";
import HelmetMetaData from '../Common/Default';
class HowIWork extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      items: workingprocess,
    };
  }
  componentDidMount(){
    window.scrolltoTop();
  }
  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loader">Loading...</div>;
    } else {
      const workprocess = items.map((process, i) => {
        return (
          <div key={i} className="col-md-6">
            <div className="how-i-work-box">
              <div className="how-i-work-title">
                <div className="number">{process.id}</div>
                <h4 className="t-color-blue">{process.title}</h4></div>
              <p>{process.description}</p>
            </div>
          </div>
        );
      });
      return (
        <>
          <HelmetMetaData title="How I Work | Chauhan Vijay"
            description="Well begun is half done! And so, I begin well with perfect strategic plans. My clients are majorly involved in the planning process | Chauhan Vijay"
            keywords="How I Work"
          ></HelmetMetaData>
          <Breadcrum pagetitle="How I Work" breadcrumclassName="inner-banner" />
          <div className="container-fluid">
            <section className="c-padd-80 how-i-work-page">
              <div className="container">
                <div className="row">
                  {workprocess}
                </div>
              </div>
            </section>
          </div>
        </>
      );
    }
  }
}
export default HowIWork;
