import React from 'react';
const ContactAddress = () => {
  return (
    <><div className="col-lg-5 col-md-12">
      <div className="contact-block">
        <h3 className="t-color-blue">CONTACT US</h3>
        <div className="contact-box">
          <div className="contact-img">
            <img src="images/contact-img-22.png" alt="Contact Us" />
          </div>
          <div className="contact-text">
            <h4>Email</h4>
            <p><a href="mailto:chauhanvijay.mca@gmail.com">chauhanvijay.mca@gmail.com</a></p>
          </div>
        </div>
        <div className="contact-box">
          <div className="contact-img">
            <img src="images/contact-img-33.png" alt="Chauhan Vijay" />
          </div>
          <div className="contact-text">
            <h4>phone number</h4>
            <p><a href="tel:(+91) 9574689275">(+91) 9574689275</a></p>
          </div>
        </div>
        <div className="contact-box">
          <div className="contact-img">
            <img alt="Contact Us" src="images/contact-img-44.png" />
          </div>
          <div className="contact-text">
            <h4>Hours of Operation</h4>
            <p>Monday - Friday: 09:00 - 20:00 <br />Sunday & Saturday: 10:30 - 22:00 </p>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}
export default ContactAddress;