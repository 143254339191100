import React from "react";

const ServiceBlock = (props) => {
  return (
    <>
      <div className="help-box">
        <div className="help-img">
          <img className="img-fluid" src={props.homeimage} alt={props.title} />
        </div>
        <h5>{props.title}</h5>
      </div>
    </>
  );
};
export default ServiceBlock;
