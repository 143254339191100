import React from "react";
var parse = require('html-react-parser');
const AboutValue = (props) => {
  return (
    <>
      <h4 className="t-color-blue mb-10">{props.title}</h4>
      {parse(props.content)}
    </>
  );
};
export default AboutValue;
