import React, { Component } from 'react'

import { FloatingWhatsApp } from 'react-floating-whatsapp-button'
import 'react-floating-whatsapp-button/dist/index.css'


class WhatsApp extends Component {
  render() {
    return <FloatingWhatsApp showPopup="true" phone="919574689275" />
  }
}
export default WhatsApp