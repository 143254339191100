import React from "react";
import ServiceBlock from "../Common/ServiceBlock";
import services from '../api/services.json';

class ServiceInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      items: services,
    };
  }
  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loader">Loading...</div>;
    } else {
      const ServiceData = items.map((value, i) => {
        return (
          <div key={i} className="col-lg-3 col-md-6 col-sm-6 col-6">
            <ServiceBlock
              title={value.title}
              description={value.description}
              image={window.location.origin + "/images/" + value.image}
              homeimage={window.location.origin + "/" + value.homeimage}
              content={value.content}
            />
          </div>
        );
      });
      return (
        <>
          <div className="container">
            <h3 className="h2 t-color-blue text-center mb-30">I Can Help With...</h3>
            <div className="row">
              {ServiceData}
            </div>
          </div>
        </>
      );
    }
  }
}
export default ServiceInfo;
