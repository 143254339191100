import React from "react";
class ProjectInfo extends React.Component {

  render() {
    return (
      <>
        <div className="container">
    <div className="row">
      <div className="col-lg-3 col-md-6 col-sm-6 col-6">
        <div className="count-box">
          <h5><span>{(new Date().getFullYear()) - 2015}+</span>YEARS OF EXPERIENCE</h5>
        </div>
      </div>

      <div className="col-lg-3 col-md-6 col-sm-6 col-6">
        <div className="count-box">
          <h5><span>100+</span>PROJECTS</h5>
        </div>
      </div>

      <div className="col-lg-3 col-md-6 col-sm-6 col-6">
        <div className="count-box">
          <h5><span>50+</span>HAPPY CLIENTS</h5>
        </div>
      </div>

      <div className="col-lg-3 col-md-6 col-sm-6 col-6">
        <div className="count-box">
          <h5><span>100k+</span>LINE OF CODE</h5>
        </div>
      </div>
    </div>
  </div>
      </>
    );
  };
}
export default ProjectInfo;
