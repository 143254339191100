import React from "react";
import { NavLink } from "react-router-dom";
import workingprocess from '../api/workingprocess.json';
class WorkingProcess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      items: workingprocess,
    };
  }
  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loader">Loading...</div>;
    } else {
      const workprocess = items.map((process, i) => {
        return (
          <div key={process.id} className="col-lg-3 col-md-6 col-sm-6">
            <div className="how-work-box">
              <div className="number">{process.id}</div>
              <h4 className="t-color-blue">{process.title}</h4>
              <p>{process.description}</p>
              <NavLink
                exact
                to="/how-i-work"
                activeClassName="active"
                className=""
              >
                <i className="fas fa-arrow-right"></i>
              </NavLink>
            </div>
          </div>
        );
      });
      return (
        <>
          <div className="container-fluid">
            <h3 className=" h2 t-color-blue mb-30 text-center t-color-white">How I Works</h3>
            <div className="row">
              {workprocess}
            </div>
          </div>
        </>
      );
    }
  }
}
export default WorkingProcess;
