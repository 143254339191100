import React from "react";
import PortfolioBlock from '../Common/PortfolioBlock';
import portfolio from '../api/portfolio.json';
class PortfolioInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      items: portfolio,
    };
  }
  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="loader">Loading...</div>;
    } else {
      const PortfolioData = items.map((value, i) => {
        return (
          <div key={i} className="col-lg-6 col-md-6">
            <PortfolioBlock
              title={value.title}
              slug={value.slug}
              description={value.description}
              image={
                window.location.origin +
                "/img/projects/" + value.image
              }
              content={value.content}
            />
          </div>
        );
      });
      return (
        <>
          <div className="container">
            <div className="row">
              {PortfolioData}
            </div>
          </div>
        </>
      );
    }
  }
}
export default PortfolioInfo;
