import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";


export default function HelmetMetaData(props) {
   let location = useLocation();
   let currentUrl = window.location.origin + location.pathname;
   let title = props.title !== undefined ? props.title : "Web Design & Development Company Vadodara";
   let image = props.image !== undefined ? props.image : window.location.origin + 'images/slider-img.jpg';
   let description = props.description !== undefined ? props.description : "I'm Vijay Chauhan a Professional Full-stack Website Designer & Web Developer with 5+ years of experience. In the Last 5 years, I've helped 50+ businesses to improve their product experience With Latest and Highly secured technologies like Laravel, Codeigniter, Andriod App & Moodle I have built 100+ websites for a broad range of industries from small and mid-level businesses to high-end companies & still counting... My Cleared Aim has always been to build a website that looks Professional, Secured, Reliable, and AFFORDABLE. Let's Plan your Project & Build Robust Solutions.";
   let keywords = props.keywords !== undefined ? props.keywords : "Full-stack Website Developer, Web Design, E-Commerce Development";
   return (
      <Helmet>
         <title data-react-helmet="true">{title}</title>
         <meta name="description" content={description} />
         <link rel="canonical" href={currentUrl} />
         <meta name="robots" content="index, follow" />
         <meta property="og:type" content="portfolio" />
         <meta property="og:title" content={title} />
         <meta property="og:description" content={description} />
         <meta property="og:image" content={image} />
         <meta property="og:url" content={currentUrl} />
         <meta property="og:site_name" content="Chauhan Vijay | Web Design & Development" />
         <meta data-react-helmet="true" property="keywords" content={keywords} />
      </Helmet>
   );
}
