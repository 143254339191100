import React from "react";
import MenuLink from '../Common/MenuLink';
class Footer extends React.Component {
  render() {
    return (
      <>
        <footer>
          <div className="container-fluid c-padd-40">
            <div className="row ">

              <div className="col-lg-3 col-md-6 col-sm-6 footer-block">
                <img src={window.location.origin + "/images/footer-logo.png"} alt="Chauhan Vijay Logo" className="img-fluid mb-10" />
                <p>We experience using the latest website building technologies to give out the most immeasurable performing site.</p>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 footer-block pl-lg-5">
                <h5 className="text-uppercase mb-20">Quick Link</h5>
                <ul className="menu-link">
                  <MenuLink />
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 footer-block">
                <h5 className="text-uppercase mb-20">Our Services</h5>
                <ul className="menu-link">
                  <li><a href="#/">Web Design</a></li>
                  <li><a href="#/">Web Development</a></li>
                  <li><a href="#/">E-Commerce Development</a></li>
                  <li><a href="#/">Mobile App Development</a></li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-6 footer-block">
                <h5 className="text-uppercase mb-20">Follow Us On</h5>
                <ul className="follow-us">
                  <li><a rel="noopener noreferrer" href="https://www.facebook.com/chauhanvijay.in" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                  <li><a rel="noopener noreferrer" href="https://in.linkedin.com/in/chauhan-vijay-9299a4b5" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                  <li><a rel="noopener noreferrer" href="https://www.instagram.com/chauhan_viiay/" target="_blank"><i className="fab fa-instagram"></i></a></li>

                </ul>
              </div>


            </div>
          </div>

          <div className="container-fluid copy-right-section">
            <p>Copyright &#169; {(new Date().getFullYear())} All Rights Reserved by Chauhan Vijay</p>
          </div>
        </footer>
      </>
    );
  }
}
export default Footer;
