import React from "react";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import Navbar from "./Common/Navbar";
import WhatsApp from "./Common/WhatsApp";
import Footer from "./Common/Footer";
import Home from "./Pages/Home";
import Service from "./Pages/Service";
import About from "./Pages/About";
import Portfolio from "./Pages/Portfolio";
import Contact from "./Pages/Contact";
import ProjectDetail from "./Pages/ProjectDetail";
import HowIWork from "./Pages/HowIWork";
import { Switch, Route } from "react-router-dom";
import HelmetMetaData from "./Common/Default"
const App = () => {
  return (
    <>
      <HelmetMetaData></HelmetMetaData>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/how-i-work" component={HowIWork} />
        <Route exact path="/service" component={Service} />
        <Route exact path="/portfolio" component={Portfolio} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/portfolio/:slug" component={ProjectDetail} />
      </Switch>
      <Footer />
      <WhatsApp />
    </>
  );
};
export default App;
